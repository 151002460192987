import React from "react";
import "./src/styles/global.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { GlobalProvider } from "./src/contexts/GlobalContext";

import type { GatsbyBrowser } from "gatsby";

const App: React.FC<any> = (props) => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return <GlobalProvider>{props.children}</GlobalProvider>;
};

const Wrapper: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return <App>{element}</App>;
};

export const wrapPageElement = Wrapper;
