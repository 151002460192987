exports.components = {
  "component---src-builder-greeting-index-tsx": () => import("./../../../src/builder/greeting/index.tsx" /* webpackChunkName: "component---src-builder-greeting-index-tsx" */),
  "component---src-builder-news-index-tsx": () => import("./../../../src/builder/news/index.tsx" /* webpackChunkName: "component---src-builder-news-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-index-tsx": () => import("./../../../src/pages/join-us/index.tsx" /* webpackChunkName: "component---src-pages-join-us-index-tsx" */),
  "component---src-pages-our-services-index-tsx": () => import("./../../../src/pages/our-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-index-tsx" */),
  "component---src-pages-our-services-portfolio-tsx": () => import("./../../../src/pages/our-services/portfolio.tsx" /* webpackChunkName: "component---src-pages-our-services-portfolio-tsx" */),
  "component---src-pages-our-services-products-tsx": () => import("./../../../src/pages/our-services/products.tsx" /* webpackChunkName: "component---src-pages-our-services-products-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

